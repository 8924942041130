/* ql button and animation  */


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body{font-family: 'Gilroy-regular';}

/* SIZE  */
.text-3-5xl {
    font-size: 2rem;
}

.max-w-screen-5xl {
    max-width: 1678px;

}

/* COLOR  */
.bg-voilet-900 {
    background-color: #5C3EB3;
}

.primary-color {
    color: #5C3EB3;
}

.bg-top-talent {
    background: #5C3EB3;
    /* background: linear-gradient(180deg, #4B2F9E 0%, #230A70 100%); */


}

.border-primary-color {
    border-color: 1px #5C3EB3;
}

.gredient-color {
    background: linear-gradient(180.37deg, #5C3EB3 0.32%, rgba(92, 62, 179, 0.938355) 44.99%, rgba(92, 62, 179, 0.740341) 108.52%, rgba(92, 62, 179, 0.636043) 120.27%, rgba(92, 62, 179, 0.36748) 120.29%, rgba(92, 62, 179, 0) 121.8%, rgba(92, 62, 179, 0.693556) 121.8%, rgba(92, 62, 179, 0.253548) 121.8%, rgba(92, 62, 179, 0.494779) 121.8%);
}

.grdient-navbar {
    background: linear-gradient(180.37deg, #5C3EB3 0.32%, rgb(92 62 178) 44.99%);
}


/* Navbar Bg-Color */
.hero_bg_gradient{
    background: linear-gradient(180deg, #7c40df 0%, #331856 100%);
}


.client_logo_heading , .hire-talent-heading{
    color: #232323;
}
    
/* .client_logo_heading::after{
content: '';
height: 1px;
width: 25px;
background: #232323;
z-index: 0;
position: absolute;
top: 50%;
margin-left: 5px;

} */

.trusted_para::before{
    content: '';
    height: 1px;
    width: 25px;
    background: #232323;
    z-index: 0;
    position: absolute;
    top: 32%;
    margin-left: -29px;
    
    }
    .hero {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 50px;
    
        background-image: url("https://ql-web-images.s3.us-east-2.amazonaws.com/ql-images/clients-logos1.png");
        background-repeat: repeat-x;
        font-family: "Montserrat", sans-serif;
        text-align: center;
        background-size: cover;
        animation: marquee 30s infinite linear;}
    
        @media screen and (max-width: 768px) {
            .hero {
        height: 30px;
        background-image: url("https://ql-web-images.s3.us-east-2.amazonaws.com/ql-images/clients-logos-mobile.png");
       }
    }
    
      
      @keyframes marquee {
        0% {
          background-position: 0;
        }
      
        100% {
          background-position: 100%;
        }
      }

/* Navbar Bg-Color */


/* .text-redish-300{
    color: #CE698B;
} */
/* .bg-offwhite{
    background-color: #FBFBFD;
} */
.bg-offwhite-100 {
    background: rgba(5, 5, 5, 0.06);
}

/* .peach-light{
    color: #E0E8B7;
} */
.peach-medium {
    background: #FFF1F1;
}

.peach-lightest {
    background: #FFF6F1;
}

.contact-negative {
    height: 60rem;
}

/* .Montserrat {
    font-family: 'Montserrat', sans-serif;
} */



/* POSITION  */
.inset-center {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* h1 > span{
    font-family: 'Montserrat', sans-serif;
} */


.hire-top-talent h1>span {
    font-family: 'Gilroy-Bold', sans-serif;
}



/* ANIMATION  */

#circle {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
}

#circle:before {
    content: "";
    position: absolute;
    width: 8rem;
    height: 8rem;
    border: 2px solid #c5ccda;
    border-radius: 50%;
    animation: ping 1s cubic-bezier(0, 0, .2, 1) infinite;
}

/* ql button and animation ends  */

.hide {
    display: none;
}

.show {
    display: block;
}


/* ANIMATION  */

/* NAVBAR LINK BOTTOM LINE ANIMATION START */
.line-base:hover:after {
    content: '';
    background-color: #FF7C7C;
    height: 2px;
    width: 5rem;
    bottom: 0rem;
    position: absolute;
    left: 0;
}

/* NAVBAR LINK BOTTOM LINE ANIMATION END  */

/* FOOTER SOCIAL MEDIA ICON COLOR CHANGE START */
.social-fillup:hover {
    background-color: #5C3EB3;

}

.social-fillup:hover .foot-hover {
    fill: white !important;
}


/* .hero {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 50px;
    background-image: url("https://cdn.quokkalabs.com/ql-images/clients-logos.webp");
    background-repeat: repeat-x;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    background-size: cover;
    animation: marquee 30s infinite linear;} */
  
  
  /* @keyframes scroll-x { 100% { transform: translate(calc(-50% - .5rem)); } } */
  
  
  
  
  /* @keyframes marquee {
    0% {
      background-position: 0;
    }
  
    100% {
      background-position: 100%;
    }
  }
   */
/* FOOTER SOCIAL MEDIA ICON COLOR CHANGE END */

.mnreImage {
    max-width: 120%;
}
.bg-service-work{
    background-image: url('../images/Purple-gradient.png');
}

@media screen and (max-width:768px) {
    .mnreImage {
        max-width: 100%;
    }
    .bg-service-work{
        background-image: url('../images/purple-mobile-work.png');
    }
}

#slider {
    position: relative;
    width: 500px;
    height: 400px;
    overflow: hidden;
}


#slider ul {
    position: relative;
    list-style: none;
    height: 100%;
    width: 10000%;
    padding: 0;
    margin: 0;
    transition: all 750ms ease;
    left: 0;
}

#slider ul li {
    position: relative;
    height: 100%;

    float: left;
}

#slider ul li img {
    width: 500px;
    height: 400px;
}

#slider #prev,
#slider #next {
    width: 50px;
    line-height: 50px;
    text-align: center;
    color: white;
    text-decoration: none;
    position: absolute;
    top: 50%;
    border-radius: 50%;
    font-size: 2rem;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
    transform: translateY(-50%);
    transition: all 150ms ease;
}

#slider #prev {
    left: 10px;
}

#slider #next {
    right: 10px;
}

#slider #prev:hover,
#slider #next:hover {
    background-color: rgba(0, 0, 0, 0.5);
    text-shadow: 0;
}

/* =banner index == */
.listTpyo {
    color: rgb(69, 69, 69);
    font-family: Gilroy-Regular;
    font-size: 17px;
    line-height: 1.9rem;
    list-style-type: disc !important;
    padding-left: 0;
}

.bg-violet:hover {
    background-color: rgb(167 139 250);
    transition: all 0.3s;
}

.listTpyo::marker {
    margin: 0px;
    padding: 0px;
}


/* Hire Talent Page Start */

.hire_bg{
    background: #F9F7FC;
}


.select_tech{
    background:#EEEAFF ;
    border-color:transparent;
}

.search_list_hover.select_tech > div span{
color: #7D63C8;
}

.search_list_hover:hover{
 background: #F1ECFF;
}

#hire_form_loader {
    width: 3rem;
    height: 3rem;
    border: 5px solid #f3f3f3;
    border-top: 6px solid #9c41f2;
    border-radius: 100%;
    margin: 15px;
    animation: spin 1s infinite linear;
    margin-left: auto;
    margin-right: auto;
}
#hire_form_loader.display {
    visibility: visible;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Chrome, Safari, Edge, Opera */
input#Client_Mobile::-webkit-outer-spin-button,
input#Client_Mobile::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* Hire Talent Page Finish */





@media screen and (max-width: 768px) {
    .swiper-slide img {
        object-fit: fill !important;
    }
}



.blogsImage{width: 40px!important;height: 40px!important;}
.blogTimeImage{width: 20px!important;height: 20px!important;}
.blogImageMain{object-fit: fill!important;width:100%}


.open-connect{display: block;}






@media screen and (min-width: 1024px) and (max-width: 1280px){
    .ml_lets_65{
        margin-left: 35px !important;
    }
    .ml_lets_42{
        margin-left: 20px !important;
    }
}


@media screen and (min-width:1281px) {
    .ml_lets_65{
        margin-left: 65px !important;
    }
    .ml_lets_42{
        margin-left: 42px !important;
    }
}


